(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define('lib/csrf',['jquery'], factory);
    } else if (typeof exports === 'object') {
        // CommonJS
        factory(require('jquery'));
    } else {
        // Browser globals
        factory(jQuery);
    }
}(function ($) {

    var getCookie = function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    };

    var csrfSafeMethod = function (method) {
        // these HTTP methods do not require CSRF protection
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    };

    $(document).ajaxSend(function(event, jqxhr, settings) {
        if (!csrfSafeMethod(settings.type)) {
            var csrfToken = getCookie("csrfToken");
            if (csrfToken != null) {
                jqxhr.setRequestHeader("X-CSRF-Token", csrfToken);
            }
        }
    });

}));

