define('customer/pages/search',[
    'lib/suggestions'
], function () {

    $(document).ready(function(){

        $('#HeaderSearchDisplayForm').submit(function(){
            var search_phrase = $.trim($('#HeaderSearchSearchPhrase').val());

            if(!search_phrase.length)
            {
                alert('Please enter a keyword you wish to search.');
                return false;
            }

            //Cut too long phrases
            if(search_phrase.length > 150)
            {
                search_phrase = search_phrase.substr(0, 150);
                $('#HeaderSearchSearchPhrase').val(search_phrase);
            }

            /* Change search form actions depending on page we landed */
            if(document.URL.indexOf(document.domain + '/Browse/Seller') > 0
               || document.URL.indexOf(document.domain + '/Browse/Location') > 0
               || document.URL.indexOf(document.domain + '/Browse/State') > 0){
                var form_action = '/Browse/Seller/Search:' + encodeURIComponent(search_phrase);
            }
            else if(document.URL.indexOf(document.domain + '/Browse/Publishers') > 0){
                var form_action = '/Browse/Publishers/Search:' + encodeURIComponent(search_phrase);
            }
            else{
                var form_action = cfg.Search.base + '/Search:' + encodeURIComponent(search_phrase);
            }
            $('#HeaderSearchDisplayForm').attr('action');
            window.location.href = form_action;

            return false;
            /* END Change search form actions depending on page we landed */
        });

        $('.submit_area_holder .submit_area').click(function(){
            $('#HeaderSearchDisplayForm').submit();
        });

        //search suggestion initialization
        $('#HeaderSearchSearchPhrase').searchSuggestion(
            {
                'min_chars': cfg.search_suggestions.min_chars,
                'ga_push_timeout': cfg.search_suggestions.ga_push_timeout,
                'use_ls': cfg.search_suggestions.use_local_storage,
                'url': cfg.search_suggestions.base_url,
                'search_sellers' : cfg.search_suggestions.search_sellers !== undefined
            }
        );

    });
});

