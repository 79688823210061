// Common caching mechanism with fallback from localStorage -> sessionStorage -> memory
var Cache = (function() {
    // we can't rely on $.isEmptyObject to return true for empty arrays
    var isEmptyArrayOrObject = function (obj) {
        return (jQuery.isArray(obj) && obj.length === 0) || jQuery.isEmptyObject(obj);
    }

    // HTML5 Storage Base class
    var Html5StorageProvider = function Html5StorageProvider(type, options) {
        if (!options) { options = {}; }

        if (!options.prefix) {
            throw new Error('Html5StorageProvider requires prefix option.');
        }
        if (!options.prefixSeparator) {
            throw new Error('Html5StorageProvider requires prefixSeparator option.');
        }

        this.storage = window[type];
        this.type = type;
        this.options = options;
    }

    Html5StorageProvider.isSupported = function(type) {
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
        try {
            var storage = window[type],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return false;
        }
    };

    Html5StorageProvider.prototype.get = function(key) {
        return this.storage.getItem(key.trim());
    };

    Html5StorageProvider.prototype.set = function(key, value) {
        this.storage.setItem(key.trim(), value);
    };

    Html5StorageProvider.prototype.remove = function(key) {
        this.storage.removeItem(key.trim());
    };

    Html5StorageProvider.prototype.clear = function() {
        var keys = Object.keys(this.storage);
        var prefix = this.options.prefix + this.options.prefixSeparator;

        for (var x = 0; x < keys.length; ++x) {
            var key = keys[x];
            if (prefix === key.slice(0, prefix.length)) {
                this.remove(key);
            }
        }
    };

    // Local Storage
    var LocalStorageProvider = function LocalStorageProvider(options) {
        return new Html5StorageProvider('localStorage', options);
    }

    LocalStorageProvider.isSupported = function() {
        return Html5StorageProvider.isSupported('localStorage');
    };

    // Session Storage
    var SessionStorageProvider = function SessionStorageProvider(options) {
        return new Html5StorageProvider('sessionStorage', options);
    }

    SessionStorageProvider.isSupported = function() {
        return Html5StorageProvider.isSupported('sessionStorage');
    };

    // Memory Storage
    var MemoryStorageProvider = function MemoryStorageProvider() {
        this.type = 'memory';
        this.cache = {};
    }

    MemoryStorageProvider.isSupported = function() {
        return true;
    }

    MemoryStorageProvider.prototype.get = function(key) {
        return this.cache[key];
    };

    MemoryStorageProvider.prototype.set = function(key, value) {
        this.cache[key] = value;
    };

    MemoryStorageProvider.prototype.remove = function(key) {
        delete this.cache[key];
    };

    MemoryStorageProvider.prototype.clear = function() {
        this.cache = {};
    };

    // Null Storage
    var NullStorageProvider = function NullStorageProvider() {
        this.type = 'null';
    }

    NullStorageProvider.isSupported = function() {
        return true;
    }

    NullStorageProvider.prototype.get = function(key) {
        return;
    };

    NullStorageProvider.prototype.set = function(key, value) {

    };

    NullStorageProvider.prototype.remove = function(key) {

    };

    NullStorageProvider.prototype.clear = function() {

    };


    /**
     * options:
     *   - prefix           (default: --tpt-cache--)
     *   - prefixSeparator  (default: |)
     *   - expiration       (optional)
     *   - providers        (default: [LocalStorage, SessionStorage, Memory])
     **/
    var Cache = function Cache(options) {
        if (options == null) { options = {}; }
        if (options.prefix == null) { options.prefix = '--tpt-cache--'; }
        if (options.prefixSeparator == null) { options.prefixSeparator = '|'; }

        var providers = options.providers || [
            LocalStorageProvider,
            SessionStorageProvider,
            MemoryStorageProvider
        ];

        this.options = options;
        this.init(providers);
    }

    Cache.SECOND = 1000;
    Cache.MINUTE = Cache.SECOND * 60;
    Cache.HOUR = Cache.MINUTE * 60;
    Cache.DAY = Cache.HOUR * 24;
    Cache.WEEK = Cache.DAY * 7;

    Cache.Providers = {
        LocalStorage: LocalStorageProvider,
        SessionStorage: SessionStorageProvider,
        Memory: MemoryStorageProvider
    };

    Cache.prototype.init = function(providers) {
        for (var x = 0; x < providers.length; ++x) {
            var provider = providers[x];
            if (provider.isSupported()) {
                this.storage = new provider(this.options);
                return;
            }
        }

        this.storage = new NullStorageProvider(this.options);
    };

    Cache.prototype.createKey = function(key) {
        return this.options.prefix + this.options.prefixSeparator + key;
    };

    Cache.prototype.get = function(key) {
        key = this.createKey(key);
        var data = this.storage.get(key);
        if (data == null) { return; }

        var match = /^([0-9]+)\|(.+)$/.exec(data);
        if (!match) {
            this.storage.remove(key);
            return;
        }

        if (this.options.expiration != null) {
            var ts = parseInt(match[1]);
            var now = Date.now();
            if ((now - ts) > this.options.expiration) {
                this.storage.remove(key);
                return;
            }
        }

        return JSON.parse(match[2]);
    };

    Cache.prototype.set = function (key, value) {
        try {
            this.storage.set(this.createKey(key), Date.now() + '|' + JSON.stringify(value));
        } catch (e) {}
    };

    Cache.prototype.remove = function(key) {
        return this.storage.remove(this.createKey(key));
    };

    Cache.prototype.clear = function() {
        return this.storage.clear();
    };

    return Cache;
})();


// export Cache for testing
if (typeof(module) === 'object' && typeof(exports) === 'object') {
    exports.Cache = Cache;
}


/*** Start of autosuggest JS ***/
(function( $ ){

    var element;
    var $input;
    var list_index = -1;
    var settings;
    var prev_phrase;
    var prev_ajax;
    var lc_dummy = '#TOP_SEARCHES#';
    var timeoutId;
    var is_popular = false;
    var text_split_limit = 42;
    var cache;

    var isEmptyArrayOrObject = function (obj) {
        return (jQuery.isArray(obj) && obj.length === 0) || jQuery.isEmptyObject(obj);
    }

    var methods = {

        escapeHtml : function(text) {
            return text
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        },

        decodeHtml : function(text) {
            return text
                .replace(/&amp;/g, "&")
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, "\"")
                .replace(/&#039;/g, "'");
        },

        init : function(options) {
            settings = $.extend( {
                'url'       : cfg.search_suggestions.base_url || 'https://suggest-production.teacherspayteachers.com/suggestions',
                'get_timeout' : 50,
                'div_class' : 'suggestions',
                'use_ls'    : 1,
                'clear_ls_mode' : 5 * Cache.MINUTE,
                'min_chars' : 1,
                'exp_time_key' : 'first_local_storage_set_time',
                'ga_push_timeout' : 0,
                'ls_prefix' : 'sugg_prefix_'
            }, options);

            cache = new Cache({prefix: settings.ls_prefix, expiration: settings.clear_ls_mode});

            element = $("<div/>").appendTo($('body'));
            $input = $(this.selector);

            $input.on('keyup', methods.update);
            $input.on('focusout', methods.hide);
            $input.on('focusin', methods.topsearches);
            $input.on('keydown', methods.navigation);

            $input.parent('form').keypress(function(e){
                if ( e.which == 13 ) {
                    e.preventDefault();
                    return false;
                }
            });
        },

        update : function(eventObject) {

            if($.inArray(eventObject.keyCode, [13, 37, 38, 39, 40, 123]) != -1)
                return;

            var phrase = $.trim($(this).val());

            var offset = $(this).offset();


            element.css('position', 'absolute')
                .css('left', offset.left)
                .css('top', offset.top + this.offsetHeight)
                .addClass(settings['div_class']);

            //Cut too long phrases
            if(phrase.length > 150)
            {
                phrase = phrase.substr(0, 150);
                $(this).val(phrase);
            }

            if(phrase.length >= settings['min_chars']){
                if(cache.get(phrase)){
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(function(){
                        methods.genList(cache.get(phrase), phrase);
                    }, settings['get_timeout']);
                }else{
                    if(phrase != prev_phrase){
                        prev_phrase = phrase;
                        if(prev_ajax){
                            prev_ajax.abort();
                        }

                        clearTimeout(timeoutId);
                        timeoutId = setTimeout(function(){
                            prev_ajax = $.getJSON(settings['url'] + '?includeResourceTypes=1&includeSearches=1&includeSellers=1&query=' + encodeURIComponent(phrase), function(suggestions){
                                methods.genList(suggestions, phrase);
                            });
                        }, settings['get_timeout']);
                    }
                }
            } else {
                methods.topsearches($(this));
            }
        },

        update_resize : function(input) {

            var phrase = $.trim($input.val());

            var offset = $input.offset();

            element.css('position', 'absolute')
                .css('left', offset.left)
                .css('top', offset.top + $input.outerHeight())
                .addClass(settings['div_class']);

            if(phrase.length >= settings['min_chars'])
            {
                if(cache.get(phrase)){
                    methods.genList(cache.get(phrase), phrase);
                }else{
                    if(phrase != prev_phrase){
                        prev_phrase = phrase;
                        if(prev_ajax){
                            prev_ajax.abort();
                        }
                        prev_ajax = $.getJSON(settings['url'] + '?includeResourceTypes=1&includeSearches=1&includeSellers=1&query=' + encodeURIComponent(phrase), function(suggestions){
                            methods.genList(suggestions, phrase);
                        });
                    }
                }
            } else {
                methods.topsearches($(this));
            }
        },

        topsearches : function(input) {

            if(!input.jquery)
                $input = $(this);

            if(!$input.val().length)
            {
                var phrase = $.trim($input.val());

                //Show autosuggest on input focus
                if(phrase.length)
                {
                    var offset = $input.offset();

                    element.css('position', 'absolute')
                        .css('left', offset.left)
                        .css('top', offset.top + this.offsetHeight)
                        .addClass(settings['div_class']);

                    if(phrase.length >= settings['min_chars'])
                    {
                        if(cache.get(phrase)){
                            clearTimeout(timeoutId);
                            timeoutId = setTimeout(function(){
                                methods.genList(cache.get(phrase), phrase);
                            }, settings['get_timeout']);
                        }else{
                            prev_phrase = phrase;
                            if(prev_ajax){
                                prev_ajax.abort();
                            }

                            clearTimeout(timeoutId);
                            timeoutId = setTimeout(function(){
                                prev_ajax = $.getJSON(settings['url'] + '?includeResourceTypes=1&includeSearches=1&includeSellers=1&query=' + encodeURIComponent(phrase), function(suggestions){
                                    methods.genList(suggestions, phrase);
                                });
                            }, settings['get_timeout']);
                        }
                    } else {
                        methods.topsearches($input);
                    }

                    return;
                }

                if(!phrase.length && settings.search_sellers){
                    methods.hide();
                    return false;
                }

                var offset = $input.offset();

                element.css('position', 'absolute')
                    .css('left', offset.left)
                    .css('top', offset.top + $input.height() + 12) //add padding
                    .addClass(settings['div_class']);

                prev_phrase = phrase;

                if (cache.get(lc_dummy)) {
                    clearTimeout(timeoutId);
                    methods.genList(cache.get(lc_dummy), phrase);
                }
                else {
                    if (prev_ajax) {
                        prev_ajax.abort();
                    }
                    clearTimeout(timeoutId);
                    prev_ajax = $.getJSON(settings['url'] + '?includeSearches=1&query=', function (suggestions) {
                        methods.genList(suggestions, phrase);
                    });
                }
            }
        },

        hide : function(){
            element.hide();
        },

        ga_push : function(action, label){

            gaTracker.sendEventToGA('Site Search', action, label);
        },

        paste : function(){
            var text = $(this)
                .html()
                .replace(new RegExp('<b>'), '')
                .replace(new RegExp('</b>'), '')
                .replace(new RegExp('<B>'), '')
                .replace(new RegExp('</B>'), '');

            if($(this).attr('class') && $(this).attr('class').indexOf('seller') != -1) {
                text = $(this).find('span').html()
                    .replace(new RegExp('<b>'), '')
                    .replace(new RegExp('</b>'), '')
                    .replace(new RegExp('<B>'), '')
                    .replace(new RegExp('</B>'), '');
            }

            if($(this).attr('rel')){
                $input.parent('form').attr('action', $(this).attr('rel')).attr('method', 'get');
                $(this).children('span').remove();
                $(this).children('i').remove();
            }

            var ga_type = 'Suggestion Click';
            var ga_label = text;
            var action = 'submit';

            if(is_popular)
                ga_type = 'Popular Now Click';

            if(typeof $(this).attr('rel') != 'undefined')
            {
                var url_rel = $(this).attr('rel');
                action = 'redirect';

                if(url_rel.indexOf('/Browse') != -1)
                    ga_type = 'ResourceClick';
                else
                    ga_type = 'Seller Click';
            }

            methods.ga_push(ga_type, ga_label);

            $input.val(methods.decodeHtml(text)).focus();
            element.hide();

            var search_term = $input.val();
            $input.val(search_term.substr(0, 150));

            if(action == 'submit')
                setTimeout(function(){ $input.parent('form').submit(); }, settings['ga_push_timeout']);
            else
                setTimeout(function(){ document.location.href = url_rel; }, settings['ga_push_timeout']);
        },

        raw_search : function(){

            var text = $(this)
                .attr('rel')
                .replace(new RegExp('<b>'), '')
                .replace(new RegExp('</b>'), '')
                .replace(new RegExp('<B>'), '')
                .replace(new RegExp('</B>'), '');

            var ga_type = 'See All Click';
            var ga_label = text;

            methods.ga_push(ga_type, ga_label);

            $input.val(methods.decodeHtml(text)).focus();
            element.hide();

            var search_term = $input.val();
            $input.val(search_term.substr(0, 150));

            setTimeout(function(){ $input.parent('form').submit(); }, settings['ga_push_timeout']);
        },

        raw_search_enter : function(li){

            var text = li
                .attr('rel')
                .replace(new RegExp('<b>'), '')
                .replace(new RegExp('</b>'), '')
                .replace(new RegExp('<B>'), '')
                .replace(new RegExp('</B>'), '');

            var ga_type = 'See All Click';
            var ga_label = text;

            methods.ga_push(ga_type, ga_label);

            $input.val(methods.decodeHtml(text)).focus();
            element.hide();

            var search_term = $input.val();
            $input.val(search_term.substr(0, 150));

            setTimeout(function(){ $input.parent('form').submit(); }, settings['ga_push_timeout']);
        },

        pasteElement : function(li){
            var text = li
                .html()
                .replace(new RegExp('<b>'), '')
                .replace(new RegExp('</b>'), '')
                .replace(new RegExp('<B>'), '')
                .replace(new RegExp('</B>'), '');

            if(li.attr('class') && li.attr('class').indexOf('seller') != -1) {
                text = li.find('span').html()
                    .replace(new RegExp('<b>'), '')
                    .replace(new RegExp('</b>'), '')
                    .replace(new RegExp('<B>'), '')
                    .replace(new RegExp('</B>'), '');
            }

            if(li.attr('rel')){
                $input.parent('form').attr('action', li.attr('rel')).attr('method', 'get');
                li.children('span').remove();
                li.children('i').remove();
            }

            var ga_type = 'Suggestion Click';
            var ga_label = text;
            var action = 'submit';

            if(is_popular)
                ga_type = 'Popular Now Click';

            if(typeof li.attr('rel') != 'undefined')
            {
                var url_rel = li.attr('rel');
                action = 'redirect';

                if(url_rel.indexOf('/Browse') != -1)
                    ga_type = 'ResourceClick';
                else
                    ga_type = 'Seller Click';
            }

            methods.ga_push(ga_type, ga_label);

            $input.val(methods.decodeHtml(text)).focus();
            element.hide();

            var search_term = $input.val();
            $input.val(search_term.substr(0, 150));

            if(action == 'submit')
                setTimeout(function(){ $input.parent('form').submit(); }, settings['ga_push_timeout']);
            else
                setTimeout(function(){ document.location.href = url_rel; }, settings['ga_push_timeout']);
        },

        gcSearch : function() {

            document.location.href = '/Gift-Certificate';
        },

        genList : function(suggestions, phrase){

            element.html('').show();

            //GC Search
            var show_gc = false;
            var triger_terms = cfg.search_suggestions.gc_triger_terms.split(',');

            for(i in triger_terms)
            {
                if(phrase.toLowerCase() === $.trim(triger_terms[i]).toLowerCase())
                    show_gc = true;
            }

            if(show_gc)
            {
                $('<ul class="suggest_separator hidden"><li>&nbsp;</li></ul>').appendTo(element);

                var all_list = $("<ul/>").appendTo(element);

                var li = $("<li/>").html('Are you looking for a TpT Gift Card?').addClass('gc_search').appendTo(all_list);
                li.on('mousedown', methods.gcSearch);
            }
            //EOF GC Search

            if (suggestions.searches.length && !settings.search_sellers) {
                //put separator
                if (!phrase.length) {

                    $('<ul class="suggest_separator"><li><span>&nbsp;popular now&nbsp;</span></li></ul>').appendTo(element);
                    is_popular = true;
                }
                else {
                    $('<ul class="suggest_separator"><li><span>&nbsp;suggestions&nbsp;</span></li></ul>').appendTo(element);
                    is_popular = false;
                }

                var list = $("<ul/>").appendTo(element);

                $.each(suggestions.searches, function (index, suggestion) {
                    var txt = methods.escapeHtml(suggestion.searchTerms);
                    var li = $("<li/>").html(txt).appendTo(list);
                    li.on('mousedown', methods.paste);
                });
            }

            if(!isEmptyArrayOrObject(suggestions.sellers))
            {
                //put separator
                $('<ul class="suggest_separator"><li><span>&nbsp;sellers&nbsp;</span></li></ul>').appendTo(element);
                var sellers_list = $("<ul/>").appendTo(element);

                $.each(suggestions.sellers.slice(0, 2), function(index, seller){
                    var txt = methods.escapeHtml(seller.sellerStoreName);

                    var thumb_url = '';

                    if (window.location.protocol != "https:")
                    {
                        thumb_url = seller.storeThumbUrl;
                    }
                    else
                    {
                        if (seller.storeThumbUrl !== null)
                            thumb_url = seller.storeThumbUrl.replace('http://', 'https://');
                    }

                    var li = $("<li/>").html("<img class=\"suggestion_seller_thumb\" width=\"18\" height=\"18\" src=\"" + thumb_url + "\" /><span>" + txt + "</span>").attr('rel', '/Store/' + seller.storeUrl).appendTo(sellers_list).addClass('seller');
                    li.on('mousedown', methods.paste);
                });
            }

            if(!isEmptyArrayOrObject(suggestions.resourceTypes) && !settings.search_sellers)
            {
                //put separator
                $('<ul class="suggest_separator"><li><span>&nbsp;resources&nbsp;</span></li></ul>').appendTo(element);
                var resourcetypes_list = $("<ul/>").appendTo(element);

                $.each(suggestions.resourceTypes.slice(0, 2), function(index, resourcetype){
                    var txt = methods.escapeHtml(resourcetype.name);
                    var li = $("<li/>").html(txt).attr('rel', '/Browse/Type-of-Resource/' + resourcetype.url).appendTo(resourcetypes_list);
                    li.on('mousedown', methods.paste);
                });
            }

            if(phrase.length)
            {
                if(
                    suggestions.searches.length
                        || !isEmptyArrayOrObject(suggestions.sellers)
                        || !isEmptyArrayOrObject(suggestions.resourceTypes)
                        || show_gc
                        || (settings.search_sellers && !isEmptyArrayOrObject(suggestions.sellers))
                )
                {
                    //put separator
                    $('<ul class="suggest_separator"><li>&nbsp;</li></ul>').appendTo(element);
                }

                var all_list = $("<ul/>").appendTo(element);

                phrase = methods.splitText(phrase);

                var txt = phrase.replace(new RegExp(phrase.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"), 'i'), "<b>" + methods.escapeHtml(phrase.toLowerCase()) + "</b>");

                var li = $("<li/>").html('See all results for&nbsp;' + txt).attr('rel', txt).addClass('see_all_results').appendTo(all_list);

                li.on('mousedown', function(){
                    methods.raw_search_enter(li);
                });
            }

            if(!phrase.length)
                cache.set(lc_dummy, suggestions);
            else
                cache.set(phrase, suggestions);

            list_index = -1;
        },

        navigation : function(eventObject){
            var list = element.find('li');

            //submit form without selecting something from autosuggest
            if(list_index === -1 && eventObject.keyCode === 13) {
                if(prev_ajax){
                    prev_ajax.abort();
                }
                $input.off();
                $input.parent('form').submit();
                return;
            }

            if(list.length){
                switch (eventObject.keyCode){
                case 13:

                    if(list.eq(list_index).hasClass('see_all_results') || list.eq(list_index).hasClass('gc_search'))
                    {
                        if(list_index >= 0 && list_index < list.length && list.eq(list_index).hasClass('see_all_results'))
                            methods.raw_search_enter(list.eq(list_index));

                        if(list_index >= 0 && list_index < list.length && list.eq(list_index).hasClass('gc_search'))
                            methods.gcSearch();
                    }
                    else
                    {
                        if(list_index >= 0 && list_index < list.length)
                            methods.pasteElement(list.eq(list_index));
                    }

                    break;
                case 40:

                    var next_index = list_index + 1;
                    var prev_index = list_index;

                    if(next_index > list.length - 1)
                        next_index = 0;

                    if(list.eq(next_index).parent().hasClass('suggest_separator'))
                    {
                        next_index++;
                        list_index++;

                        if(prev_index <= 0)
                            prev_index = list.length - 1;
                    }

                    list.eq(prev_index).removeClass('active');
                    list.eq(next_index).addClass('active');

                    list_index = list_index >= list.length - 1 ? 1 : list_index = ++list_index;

                    break;
                case 38:
                    if(list_index < 0)
                        list_index = list.length - 1;

                    var next_index = list_index - 1;
                    var prev_index = list_index;

                    if(next_index >= list.length - 1)
                        next_index = list.length - 2;

                    if(list.eq(next_index).parent().hasClass('suggest_separator'))
                    {
                        next_index--;
                        list_index--;
                    }

                    list.eq(prev_index).removeClass('active');
                    list_index = list_index < 0 ? list.length - 1 : --list_index;
                    list.eq(next_index).addClass('active');

                    break;
                case 0:
                    list_index = -1;
                }
            }
        },

        splitText : function(text){

            var words = text.split(' ');
            text = '';

            var part1;
            var part2;

            for(i in words)
            {
                if(words[i].length > text_split_limit)
                {
                    part1 = words[i].substr(0, text_split_limit - 1);
                    part2 = words[i].substr(text_split_limit - 1, words[i].length);

                    if(part2.length > text_split_limit)
                        part2 = methods.splitText(part2);

                    words[i] = part1 + ' ' + part2;
                }

                text += words[i] + ' ';
            }

            return text;
        }
    };

    $.fn.searchSuggestion = function(method) {
        if ( methods[method] ) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if ( typeof method === 'object' || ! method ) {
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Method ' +  method + ' does not exist on jQuery.searchSuggestion' );
        }
    };
})( jQuery );
/*** End of autosuggest JS ***/
;
define("lib/suggestions", function(){});

